import {observable, toJS} from 'mobx';

class Sort {
    static SORT_DIRECTION = {ASC : 'ASC', DESC: 'DESC'};

    @observable id;
    @observable direction;


    constructor(id, direction = Sort.SORT_DIRECTION.ASC) {
        this.id = id;
        this.direction = direction;
    }

    toParam() {
        return new Sort(
            Array.isArray(toJS(this.id)) ? this.id.join('_') : this.id,
            this.direction
        )
    }
}

export default Sort;